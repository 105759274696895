$(document).on("ready turbo:load", function() {
  console.log("Turbo finished loading the page");

  // Link to hrefs defined in 'data-load' if used
  $("div[data-load]").filter(":visible").each(function() {
    var path = $(this).attr("data-load");
    $(this).load(path);
  });

  // Link selected images to first link in section
  $("a.link-image").each(function(index, element) {
    var firstlink = $(element).closest(".page-element").find(".content a:first").attr("href");
    if (firstlink !== undefined && firstlink !== null) {
      $(element).attr("href", firstlink);
    } else {
      $(element).addClass("ajax"); // We want to pop up a window
    }
  });

  // Show the mobile menu when clicked
  $(".show-mobile-menu").on("click", function() {
    $("#link_container").css("display", ($("#link_container").css("display") == "block" ? "none" : "block"));
    $(".show-mobile-menu").toggleClass("active");
    return false;
  });

  // Make any element into a link
  $(document).on("click", ".clickable", function() {
    window.document.location = $(this).data("href");
  });

  // Linkable select lists
  $(document).on("change", ".clickable-select", function() {
    window.document.location = $(this).val();
  });

  $(document).on("click", ".clickable-reveal", function(e) {
    $("#" + $(this).data("element")).fadeToggle();
    if ($.isArray($(this).data("text"))) {
      $(this).text() == $(this).data("text")[0] ? $(this).text($(this).data("text")[1]) : $(this).text($(this).data("text")[0]);
    }
    $(this).hasClass("on") ? $(this).removeClass("on").addClass("off") : $(this).removeClass("off").addClass("on");
    e.preventDefault();
    return false;
  });

  // Shows other options if checkbox is checked
  $(".parent-checkbox").each(function(index, element) {
    var target = $(element).closest(".page-element").find($(element).data("target"));

    console.log("Checking checkbox state to update ", target)

    if ($(element).is(":checked")) {
      $(target).show(); // Show additional checkboxes if parent is checked
      console.log("Show ", target);
    } else {
      $(target).hide();
      console.log("Hide ", target);
    }
  });

  $(document).on("change", ".parent-checkbox", function() {
    var target = $(this).closest(".page-element").find($(this).data("target"));

    console.log("Checkbox updated with target ", target);

    if ($(this).is(":checked")) {
      $(target).slideDown();
      console.log("Slide down ", target);
    } else {
      $(target).slideUp();
      console.log("Slide up ", target);
    }
  });

  // Loads pages via AJAX
  $(document).on("click", "a.ajax, .ajax > a", function(e) {
    e.preventDefault();
    var url = $(this).data("url") || $(this).attr("href"); // Use provided data-url or the source href
    var display = $(this).data("target") || '#modal_overlay'; // Use provided data-target or a default ID
    $(display).html(""); // Clear any previous content
    if ($(this).data("tab")) selectTab($(this).data("tab")); // Select a tab if data-tab is provided
    if ($(this).data("fade")) $($(this).data("fade")).fadeTo(500, 0.4); // Fade the target object if provided as data-fade
    $(display).fadeIn(500).load(url); // Load content into target
    if ($(display).offset().top < -100) $("html, body").animate({ scrollTop: $(display).offset().top }, 1000); // Scroll to top if target is out of view
    // window.history.pushState('ajaxLoad', 'admin', url); // Push this state to browser history
  });

  // Simplified AJAX loading for items already in modal box
  $(document).on("click", ".replace-modal", function(e) {
    e.preventDefault();
    var url = $(this).attr("href");
    $("#modal_overlay").load(url);
  });

  $(document).on("click", ".options-toggle", function(e) {
    e.preventDefault();
    $($(this).data("toggle")).slideToggle();
    return false;
  });

  // Close modal overlays
  $(document).on("click", ".close-overlay,.close-overlay-inline", function(e) {
    e.preventDefault();
    var target = $(this).data("target") || "#modal_overlay";
    var restore = $(this).data("restore") || null
    var replacement = $(this).data("replacement") || null;
    
    if (replacement) $(target).load(replacement).fadeIn();
    else $(target).fadeOut();
    if (restore) $(restore).fadeTo(0,1);
  });

  // Slide an elament open or closed
  $(document).off("click", ".slidetoggle").on("click", ".slidetoggle", function(e) {
    e.preventDefault();
    $($(this).attr("href")).slideToggle(750);
  });

  // Fade an element visible or invisible
  $(document).on("click", ".fadetoggle", function(e) {
    e.preventDefault();
    $($(this).attr("href")).fadeToggle(750);
  });

  // Flip an element visible or invisible
  $(document).on("click", ".toggle", function(e) {
    e.preventDefault();
    $($(this).attr("href")).toggle();
  });

  // Auto resize textareas
  $(document).on("input", ".autoresize", function() {
    this.style.height = "auto";
    this.style.height = (this.scrollHeight) + "px";
  });

  $(document).on("click", ".remove-image", function(e) {
    e.preventDefault();
    $("#image_placeholder").children().fadeOut();
    $("#image_id").val($(this).attr("id"));
    $("#delete_link").fadeOut();
  });

  $(document).on("click", ".copy-to-clipboard", function() {
    const textToCopy = $(this).text();

    navigator.clipboard.writeText(textToCopy).catch(function(err) {
      console.error("Failed to copy text: ", err);
    });
    $(this).addClass("copied");
    setTimeout(function() {
      $(this).removeClass("copied");
    }, 1000);
  });

  // Force external links to open in a new window/tab
  $(document).on("click", "a", function() {
    if (this.hostname !== window.location.hostname) {
      $(this).attr("target", "_blank");
    }
  });
});
